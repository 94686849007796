import { useCallback, useMemo, useRef, useState } from "react";
import { Box, Flex, Icon, Text } from "@storyofams/react-ui";
import { usePostHog } from "posthog-js/react";
import { getLinkProps, useClickOutside } from "~lib";
import { ArrowDown, ArrowUp } from "~components/common/Icons";
import { CardsMenu } from "./CardsMenu";
import { RecipesMenu } from "./RecipesMenu";
import { ShopMenu } from "./ShopMenu";

export const Menu = ({ navigation }) => {
  const [tab, setTab] = useState(null);
  const clickRef = useRef();
  const posthog = usePostHog();
  const close = useCallback(() => setTab(null), []);
  useClickOutside(clickRef, close);
  return (
    <Box
      ref={clickRef}
      mb="12px"
      display={["none", "none", "block"]}
      mx={"auto" as any}
      bg="white"
      maxWidth="maxWidth"
      position={"relative"}
      borderRadius={"6px"}
    >
      <Flex
        justifyContent="space-between"
        height={["auto", "auto", "100%"]}
        fontSize={2}
        lineHeight="high"
        flex="1"
      >
        {navigation?.content?.link_list.slice(0, 6).map((item, i) => (
          <Flex
            pt="7px"
            pb="5px"
            cursor={"pointer"}
            width={"100%"}
            key={i}
            borderBottom={
              tab === i ? "2px solid black" : "2px solid transparent"
            }
            onClick={() => {
              // posthog.capture("Clicked Menu", {
              //   tab: item?.label || item.mega_link_item_3columns_label,
              // });
              if (item.component === "link") {
                window.location.href = getLinkProps(item.url);
                return;
              }
              if (i === tab) setTab(null);
              else setTab(i);
            }}
          >
            <Flex
              width={"100%"}
              justifyContent={"center"}
              borderRight={
                i !== navigation?.content?.link_list.length - 1 &&
                "1px solid grey200"
              }
            >
              <Text
                fontFamily={"DINPro"}
                fontWeight={500}
                css={{ textTransform: "uppercase", textOverflow: "ellipsis" }}
                mr="10px"
                whiteSpace={"nowrap"}
              >
                {item?.label || item.mega_link_item_3columns_label}
              </Text>
              {item.component !== "link" && (
                <Icon
                  fontSize={"12px"}
                  icon={tab === i ? ArrowDown : ArrowUp}
                />
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Box>
        {tab === 0 && (
          <ShopMenu close={close} shop={navigation?.content?.link_list[0]} />
        )}
        {tab === 1 && (
          <RecipesMenu
            megaData={navigation?.content?.link_list[1]}
            close={close}
          />
        )}
        {tab > 1 && (
          <CardsMenu
            megaData={navigation?.content?.link_list[tab]}
            close={close}
          />
        )}
      </Box>
    </Box>
  );
};
