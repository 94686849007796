import { useState, useEffect } from "react";
import {
  Stack,
  Icon,
  ChevronLeft,
  Flex,
  Box,
  Text,
  css,
} from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";
import { getLinkProps } from "~lib";

import { ArrowDown, ArrowUp, Cross } from "../../Icons";
import { NavigationLink } from "./NavigationLink";
import { NavigationLinkNew } from "./NavigationLinkNew";
import { NavigationTitle } from "./NavigationTitle";

export const NavigationMegaLinkMobile = ({
  megaData,
  mobileMenuOpen,
  setMobileMenuOpen,
}) => {
  const posthog = usePostHog();
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [cards, setCards] = useState(null);
  const [isShop, setIsShop] = useState(false);
  useEffect(() => {
    if (!mobileMenuOpen) {
      setCategory(null);
      setSubCategory(null);
      setIsShop(false);
      setCards(false);
    }
  }, [mobileMenuOpen]);
  return (
    <Box
      width={"100%"}
      display={["flex !important", "flex !important", "none !important"]}
    >
      <Flex flexDirection="column" width={"100%"}>
        {megaData?.link_list.slice(0, 6).map((item, i) => {
          if (item.component === "mega_link_item_no_columns") {
            return null;
          }
          return (
            <Flex
              key={item._uid}
              width={"100%"}
              borderTop={"1px solid #F7F0E8"}
              borderBottom={
                megaData?.link_list.length - 1 === i && "1px solid #F7F0E8"
              }
              height={"50px"}
              pl="18px"
              pr="8px"
              onClick={() => {
                // posthog.capture("Clicked Menu", {
                //   tab: item?.label || item.mega_link_item_3columns_label,
                // });
                if (item.label === "Shop") setIsShop(true);
                if (item.label === "Recepten") return setCategory(item);
                if (item.component === "link") {
                  window.location.href = getLinkProps(item.url);
                  return;
                } else setCards(i);
              }}
              cursor={"pointer"}
            >
              <Flex
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Text
                    css={{ textTransform: "uppercase" }}
                    fontFamily={"DINPro"}
                    fontWeight={500}
                    fontSize={"15px"}
                  >
                    {item?.label}
                  </Text>
                  {item?.image?.filename && (
                    <Box
                      css={css({
                        img: {
                          borderRadius: "md",
                        },
                      })}
                    >
                      <Image
                        width={50}
                        height={40}
                        fluid={50}
                        src={item?.image.filename}
                      />
                    </Box>
                  )}
                </Flex>
                <Box width={"43px"} pl="15px" pt={"3px"} flexShrink={0}>
                  <Icon transform="rotate(90deg)" icon={ArrowUp}></Icon>
                </Box>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {!!category && (
        <Category space={2} flexDirection="column" mb="20px">
          <Box px="16px" bg="#FCF9F7">
            {category?.label !== "Recepten" && (
              <Flex
                width="100%"
                pt="14px"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavigationTitle
                  as="button"
                  onClick={() => {
                    setIsShop(false);
                    setCategory(null);
                  }}
                >
                  <Icon
                    icon={ArrowDown}
                    fontFamily={"DINPro"}
                    fontWeight={500}
                    fontSize={"15px"}
                    transform="rotate(90deg)"
                    mr={1}
                  />
                  {"Shop"}
                </NavigationTitle>
                <Box
                  width="100%"
                  display={["flex", "flex", "none"]}
                  alignItems="center"
                  justifyContent="flex-end"
                  as="button"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Icon fontSize={3} icon={Cross} />
                </Box>
              </Flex>
            )}
            <Flex
              width="100%"
              minHeight={category?.label === "Recepten" ? "55px" : "42px"}
              alignItems="center"
              justifyContent="space-between"
              bg="#FCF9F7"
            >
              <NavigationTitle
                as="button"
                onClick={() => {
                  if (category.label === "Recepten") {
                    setCategory(null);
                  } else {
                    setCategory(null);
                    setIsShop(true);
                  }
                }}
              >
                <Icon
                  icon={ArrowDown}
                  fontFamily={"DINPro"}
                  fontWeight={500}
                  fontSize={"15px"}
                  transform="rotate(90deg)"
                  mr={1}
                />
                {category?.mega_link_item_3columns_label ||
                  category?.mega_link_item_group_label ||
                  category.label}
              </NavigationTitle>
              {category?.label === "Recepten" && (
                <Box
                  width="100%"
                  display={["flex", "flex", "none"]}
                  alignItems="center"
                  justifyContent="flex-end"
                  as="button"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Icon fontSize={3} icon={Cross} />
                </Box>
              )}
            </Flex>
          </Box>

          <Stack
            px="24px"
            flex="1"
            overflow="auto"
            space={"17px"}
            flexDirection="column"
          >
            {category.component === "mega_link_item_3columns"
              ? category.mega_link_item_3columns_items.map((item) => (
                  <NavigationLinkNew
                    key={item.title}
                    link={getLinkProps(item.url)}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      // posthog.capture("Clicked Menu", {
                      //   tab: "Shop",
                      //   item:
                      //     category?.mega_link_item_3columns_label ||
                      //     category?.mega_link_item_group_label ||
                      //     category.label,
                      //   subItem: item.title || item.text,
                      // });
                    }}
                  >
                    {item.title || item.text}
                  </NavigationLinkNew>
                ))
              : category.component === "recipe_menu"
              ? category.items.map((item) => (
                  <NavigationLinkNew
                    key={item.title}
                    link={getLinkProps(item.url)}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      // posthog.capture("Clicked Menu", {
                      //   tab:
                      //     category?.mega_link_item_3columns_label ||
                      //     category?.mega_link_item_group_label ||
                      //     category.label,
                      //   item: item.title || item.text,
                      // });
                    }}
                  >
                    {item.title || item.text}
                  </NavigationLinkNew>
                ))
              : category.mega_link_item_group_items.map((item) => (
                  <NavigationLinkNew
                    key={item.Label}
                    onClick={() => {
                      setSubCategory(item);
                    }}
                  >
                    {item.Label}
                  </NavigationLinkNew>
                ))}
            {category.component === "mega_link_item_3columns" ? (
              <NavigationLink
                bold
                link={getLinkProps(category.mega_link_item_3columns_url)}
                onClick={() => {
                  setMobileMenuOpen(false);
                  // posthog.capture("Clicked Menu", {
                  //   tab: "Shop",
                  //   item:
                  //     category?.mega_link_item_3columns_label ||
                  //     category?.mega_link_item_group_label ||
                  //     category.label,
                  //   subItem: `ALLE ${category.mega_link_item_3columns_url}`,
                  // });
                }}
              >
                ALLE {category?.mega_link_item_3columns_label?.toUpperCase()}
              </NavigationLink>
            ) : category.component === "recipe_menu" ? (
              <NavigationLink
                bold
                css={{ textTransform: "uppercase" }}
                link={"/recipes"}
                onClick={() => {
                  setMobileMenuOpen(false);
                  // posthog.capture("Clicked Menu", {
                  //   tab: "Recepten",
                  //   item: category?.button,
                  // });
                }}
              >
                {category?.button}
              </NavigationLink>
            ) : (
              <NavigationLink
                bold
                link={getLinkProps(category?.mega_link_item_group_url)}
                onClick={() => {
                  setMobileMenuOpen(false);
                  // posthog.capture("Clicked Menu", {
                  //   tab: "Shop",
                  //   item:
                  //     category?.mega_link_item_3columns_label ||
                  //     category?.mega_link_item_group_label ||
                  //     category.label,
                  //   subItem: `ALLE ${category?.mega_link_item_group_label}`,
                  // });
                }}
              >
                ALLE {category?.mega_link_item_group_label?.toUpperCase()}
              </NavigationLink>
            )}
          </Stack>
        </Category>
      )}
      {!!subCategory && (
        <Category space={2} flexDirection="column">
          <Box px="16px" bg="#FCF9F7">
            <Flex
              width="100%"
              pt="14px"
              alignItems="center"
              justifyContent="space-between"
            >
              <NavigationTitle
                as="button"
                onClick={() => {
                  setIsShop(false);
                  setCategory(null);
                }}
              >
                <Icon
                  icon={ArrowDown}
                  fontFamily={"DINPro"}
                  fontWeight={500}
                  fontSize={"15px"}
                  transform="rotate(90deg)"
                  mr={1}
                />
                {"Shop"}
              </NavigationTitle>
              <Box
                width="100%"
                display={["flex", "flex", "none"]}
                alignItems="center"
                justifyContent="flex-end"
                as="button"
                onClick={() => setMobileMenuOpen(false)}
              >
                <Icon fontSize={3} icon={Cross} />
              </Box>
            </Flex>
            <Flex
              width="100%"
              minHeight={"42px"}
              alignItems="center"
              justifyContent="space-between"
            >
              <NavigationTitle as="button" onClick={() => setSubCategory(null)}>
                <Icon
                  icon={ArrowDown}
                  fontFamily={"DINPro"}
                  fontWeight={500}
                  fontSize={"15px"}
                  transform="rotate(90deg)"
                  mr={1}
                />
                {subCategory.Label}
              </NavigationTitle>
              {/* {!isShop && (
                <Box
                  width="100%"
                  display={["flex", "flex", "none"]}
                  alignItems="center"
                  justifyContent="flex-end"
                  as="button"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Icon fontSize={3} icon={Cross} />
                </Box>
              )} */}
            </Flex>
          </Box>

          <Stack
            px="24px"
            flex="1"
            overflow="auto"
            space={"17px"}
            mb="10px"
            flexDirection="column"
          >
            {subCategory.column_menu_items.map((item) => (
              <NavigationLinkNew
                key={item.title}
                onClick={() => {
                  setMobileMenuOpen(false);
                  // posthog.capture("Clicked Menu", {
                  //   tab: "Shop",
                  //   item:
                  //     category?.mega_link_item_3columns_label ||
                  //     category?.mega_link_item_group_label ||
                  //     category.label,
                  //   subItem: item.title,
                  // });
                }}
                link={getLinkProps(item.url)}
              >
                {item.title}
              </NavigationLinkNew>
            ))}
            <NavigationLink
              bold
              link={getLinkProps(subCategory.column_menu_url)}
              onClick={() => {
                setMobileMenuOpen(false);
                // posthog.capture("Clicked Menu", {
                //   tab: "Shop",
                //   item:
                //     category?.mega_link_item_3columns_label ||
                //     category?.mega_link_item_group_label ||
                //     category.label,
                //   subItem: `ALLE ${subCategory?.Label}`,
                // });
              }}
            >
              ALLE {subCategory?.Label?.toUpperCase()}
            </NavigationLink>
          </Stack>
        </Category>
      )}
      {!!isShop && (
        <Category space={0} flexDirection="column">
          <Flex
            px="16px"
            width="100%"
            minHeight="52px"
            alignItems="center"
            justifyContent="space-between"
            bg="#FCF9F7"
          >
            <NavigationTitle
              as="button"
              onClick={() => {
                setIsShop(false);
              }}
            >
              <Icon
                icon={ArrowDown}
                fontFamily={"DINPro"}
                fontWeight={500}
                fontSize={"15px"}
                transform="rotate(90deg)"
                mr={1}
              />
              {"Shop"}
            </NavigationTitle>
            <Box
              width="100%"
              display={["flex", "flex", "none"]}
              alignItems="center"
              justifyContent="flex-end"
              as="button"
              onClick={() => setMobileMenuOpen(false)}
            >
              <Icon fontSize={3} icon={Cross} />
            </Box>
          </Flex>
          <Flex flexDirection="column" width={"100%"}>
            {megaData?.link_list[0]?.link_list.map((item, i) => {
              return (
                <Flex
                  key={item._uid}
                  width={"100%"}
                  borderTop={"1px solid #F7F0E8"}
                  borderBottom={
                    megaData?.link_list.length - 1 === i && "1px solid #F7F0E8"
                  }
                  height={"50px"}
                  pl="18px"
                  pr="8px"
                  onClick={() => {
                    // posthog.capture("Clicked Menu", {
                    //   tab: "Shop",
                    //   item:
                    //     item?.mega_link_item_3columns_label ||
                    //     item.mega_link_item_group_label,
                    // });
                    if (item.component === "mega_link_item_no_columns") {
                      setMobileMenuOpen(false);
                      window.location.href = getLinkProps(
                        item.mega_link_item_group_url
                      );
                      return;
                    } else {
                      setCategory(item);
                      setIsShop(false);
                    }
                  }}
                  cursor={"pointer"}
                >
                  <Flex
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flex
                      alignItems={"center"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Text
                        css={{ textTransform: "uppercase" }}
                        fontFamily={"DINPro"}
                        fontWeight={500}
                        fontSize={"15px"}
                      >
                        {item.component === "mega_link_item_3columns"
                          ? item?.mega_link_item_3columns_label
                          : item?.mega_link_item_group_label}
                      </Text>
                      {(item?.mega_link_item_3columns_image?.filename ||
                        item?.mega_link_item_group_image?.filename ||
                        item?.image?.filename) && (
                        <Box
                          css={css({
                            img: {
                              borderRadius: "md",
                            },
                          })}
                        >
                          <Image
                            width={50}
                            height={40}
                            fluid={90}
                            src={
                              item?.mega_link_item_3columns_image?.filename ||
                              item?.mega_link_item_group_image?.filename ||
                              item.image.filename
                            }
                          />
                        </Box>
                      )}
                    </Flex>
                    {item.component !== "mega_link_item_no_columns" && (
                      <Box width={"43px"} pl="15px" pt={"3px"} flexShrink={0}>
                        <Icon transform="rotate(90deg)" icon={ArrowUp}></Icon>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Category>
      )}
      {!!cards && (
        <Category space={0} flexDirection="column">
          <Flex
            px="16px"
            width="100%"
            minHeight="52px"
            alignItems="center"
            justifyContent="space-between"
            bg="#FCF9F7"
          >
            <NavigationTitle
              as="button"
              onClick={() => {
                setCards(null);
              }}
            >
              <Icon
                icon={ArrowDown}
                fontFamily={"DINPro"}
                fontWeight={500}
                fontSize={"15px"}
                transform="rotate(90deg)"
                mr={1}
              />
              {megaData?.link_list[cards]?.label}
            </NavigationTitle>
            <Box
              width="100%"
              display={["flex", "flex", "none"]}
              alignItems="center"
              justifyContent="flex-end"
              as="button"
              onClick={() => setMobileMenuOpen(false)}
            >
              <Icon fontSize={3} icon={Cross} />
            </Box>
          </Flex>
          {megaData?.link_list[cards].items.map((item, i) => {
            return (
              <Link
                key={item._uid}
                prefetch={false}
                href={getLinkProps(item.link)}
              >
                <Flex
                  onClick={() => {
                    setMobileMenuOpen(false);
                    // posthog.capture("Clicked Menu", {
                    //   tab: megaData?.link_list[cards]?.label,
                    //   item: item?.title,
                    // });
                  }}
                  width={"100%"}
                  borderTop={"1px solid #F7F0E8"}
                  borderBottom={
                    megaData?.link_list.length - 1 === i && "1px solid #F7F0E8"
                  }
                  height={"50px"}
                  pl="18px"
                  pr="8px"
                  cursor={"pointer"}
                >
                  <Flex
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flex
                      alignItems={"center"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Text
                        css={{ textTransform: "uppercase" }}
                        fontFamily={"DINPro"}
                        fontWeight={500}
                        fontSize={"15px"}
                      >
                        {item?.title}
                      </Text>
                      {item?.image?.filename && (
                        <Box
                          css={css({
                            img: {
                              borderRadius: "md",
                            },
                          })}
                        >
                          <Image
                            width={50}
                            height={40}
                            fluid={50}
                            src={item?.image.filename}
                          />
                        </Box>
                      )}
                    </Flex>
                    <Box width={"43px"} pl="15px" pt={"3px"} flexShrink={0}>
                      <Icon transform="rotate(90deg)" icon={ArrowUp}></Icon>
                    </Box>
                  </Flex>
                </Flex>
              </Link>
            );
          })}
          {megaData?.link_list[cards]?.isEnabled && (
            <Box p="18px" position={"absolute"} bottom={1}>
              <Flex>
                <Box>
                  <Text fontSize={"15px"} fontFamily={"DINPro"}>
                    {megaData?.link_list[cards].big_card_text}
                  </Text>
                  <Link
                    href={getLinkProps(
                      megaData?.link_list[cards].big_card_link
                    )}
                  >
                    <Flex mt="10px" alignItems={"center"}>
                      <Text fontWeight={500} lineHeight={"18px"}>
                        {megaData?.link_list[cards].big_card_button}
                      </Text>
                      <Icon
                        ml="7px"
                        fontSize={1.25}
                        transform={"rotate(90deg)"}
                        icon={ArrowUp}
                      />
                    </Flex>
                  </Link>
                </Box>

                <Box ml="5px">
                  <Image
                    width={74}
                    height={91}
                    src={megaData?.link_list[cards].big_card_image.filename}
                  />
                </Box>
              </Flex>
            </Box>
          )}
        </Category>
      )}
    </Box>
  );
};

const Category = styled(Stack)`
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 138px;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 320px;
  background-color: ${(p) => p.theme.colors.white};
  z-index: ${(p) => p.theme.zIndices.overlay};
`;
