import { FC } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

interface NavigationProps extends SystemProps {}

export const Navigation: FC<NavigationProps> = ({ children, ...props }) => {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex={200}
      as="nav"
      {...props}
    >
      <Box width="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
