import { FC } from "react";
import { Icon, css, SystemProps, Flex } from "@storyofams/react-ui";

import { borderBottom } from "styled-system";
import { Button } from "../../../../components/common/Button";
import { ArrowUp, NavUnderline } from "../../Icons";

interface NavigationLinkNewProps extends SystemProps {
  link?: string;
  active?: boolean;
  hover?: boolean;
  onClick?(): void;
}

export const NavigationLinkNew: FC<NavigationLinkNewProps> = ({
  link,
  active,
  hover,
  children,
  ...props
}) => {
  return (
    <Flex alignItems={"center"}>
      <Icon
        pt="1px"
        fontSize={"10px"}
        transform="rotate(90deg)"
        icon={ArrowUp}
        mr="7px"
        opacity={0.3}
      />
      <Button
        href={link}
        borderBottom= "1px solid transparent"
        borderRadius={"none"}
        variant="link"
        display="border-box"
        // fontFamily={"DINPro, sans-serif"}
        fontWeight={400}
        width={["100%", "100%", "auto"]}
        fontSize={"15px"}
        lineHeight={["normal", "normal", "high"]}
        color="grey800"
        position="relative"
        justifyContent={["space-between", "space-between", "center"]}
        css={css({
          "&&:hover": { borderBottom: "1px solid black" },
        })}
        {...props}
      >
        {children}
      </Button>
    </Flex>
  );
};
