import { useState } from "react";
import { Box, Text, css } from "@storyofams/react-ui";
import Image from "next/image";
import { getLinkProps } from "~lib";
import { Button } from "../Button";
import { RichText } from "../RichText";
import { Modal } from "../Modal";

export const SocialMediaPopup = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Modal
      overflow="visible"
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
      }}
    >
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
      >
        <Box
          mx="auto"
          top={-8}
          left={0}
          right={0}
          width={130}
          height={130}
          position={"absolute"}
        >
          <Image layout="fill" src={content?.hero?.filename} />
        </Box>
        <Box textAlign={"center"}>
          {content?.image?.filename && (
            <Box
              width={"100%"}
              height={"200px"}
              mt={3}
              position={"relative"}
              css={css({
                img: {
                  borderRadius: "md",
                },
              })}
            >
              <Image
                objectFit="cover"
                layout="fill"
                src={content?.image.filename}
              />
            </Box>
          )}
          <Text
            fontFamily={"DINPro"}
            fontWeight={700}
            fontSize={"28px"}
            mb={"20px"}
            mt="30px"
          >
            {content?.title.toUpperCase()}
          </Text>
          <RichText text={content?.body} mb={3} />
          <Button
            as="a"
            href={getLinkProps(content?.link)}
            width={"100%"}
            height={"48px"}
            variant="primary"
          >
            {content?.cta}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
