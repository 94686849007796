import { renderPriceRange } from ".";
import { shopifyToIntlPrice } from "./shopifyToIntlPrice";

const high = {
  two: 0.85,
  four: 0.85,
  six: 0.85,
};
const medium = {
  two: 0.9,
  four: 0.9,
  six: 0.9,
};
const low = {
  two: 0.95,
  four: 0.9,
  six: 0.85,
};
const persents = {
  high,
  medium,
  low,
};
export const countDiscount = (foundVariant, discountTag = "", quantity = 1) => {
  // const price = foundVariant
  //   ? shopifyToIntlPrice(foundVariant?.priceV2)
  //   : renderPriceRange(product.priceRange);
  const price = foundVariant?.priceV2?.amount;
  const discount = persents[discountTag.slice(3)];
  const x2Discount = (price * discount?.two).toFixed(2);
  const x4Discount = (price * discount?.four).toFixed(2);
  const x6Discount = (price * discount?.six).toFixed(2);
  const twoPcs = "€" + x2Discount;
  const fourPcs = "€" + x4Discount;
  const sixPcs = "€" + x6Discount;
  const twoDiscount = (100 - discount?.two * 100).toFixed(2);
  const fourDiscount = (100 - discount?.four * 100).toFixed(2);
  const sixDiscount = (100 - discount?.six * 100).toFixed(2);
  const quantityDiscount =
    "€" +
    (
      (quantity >= 2
        ? quantity >= 4
          ? quantity >= 6
            ? x6Discount
            : x4Discount
          : x2Discount
        : price) * quantity
    ).toFixed(2);
  return {
    twoPcs,
    fourPcs,
    sixPcs,
    twoDiscount,
    fourDiscount,
    sixDiscount,
    quantityDiscount,
  };
};
