import { FC } from "react";
import { Icon, Flex, SystemProps } from "@storyofams/react-ui";

import Link from "next/link";
import { getLinkProps } from "~lib";
import { Checkmark } from "../../Icons";
import { Text } from "../../Text";

interface NavigationUSPBannerProps extends SystemProps {
  usp?: any;
}

export const NavigationUSPBanner: FC<NavigationUSPBannerProps> = ({ usp }) => {
  return (
    <Flex
      position="relative"
      width="100%"
      py={0.75}
      px={2}
      textAlign="center"
      overflow="hidden"
      mx={"auto" as any}
      maxWidth="1332px"
      alignItems="center"
      pr={[1, 1, 1, 2]}
      justifyContent={"space-between"}
      pl={[2, 2, 2, 2]}
    >
      {usp?.map(({ text, _uid, url }, i) => (
        <Link prefetch={false} href={getLinkProps(url)} key={_uid}>
          <Flex width={"maxContent"} cursor={"pointer"}>
            <Icon icon={Checkmark} fontSize={2} mr={1} color="greenish" />

            <Text fontWeight="medium" fontSize={1.5}>
              {text}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  );
};
