import React, { CSSProperties } from "react";
import { Stack, InputWrapperProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import styled from "styled-components";
import { ResponsiveValue } from "styled-system";

import { InputWrapper } from "../Forms";
import { Text } from "../Text";

const StyledRadio = styled.input<{ width?: string; height?: string }>`
  appearance: none;
  outline: none;
  border: none;
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "24px"};
  position: relative;
  margin-right: ${({ theme }) => theme.space[1]}px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey800};
  transition: border-shadow 0.18s ease-in-out,
    background-color 0.18s ease-in-out, 0.18s box-shadow ease-in-out;

  &:checked {
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: ${({ theme }) => theme.colors.grey800};
      width: ${(props) => (props.width ? "8px" : "15px")};
      height: ${(props) => (props.height ? "8px" : "15px")};
      border-radius: 50%;
      transition: background-color 0.18s ease-in-out;
    }
  }
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    outline: none;
    background: ${({ theme }) => theme.colors.grey100};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const Radio = ({
  value,
  label,
  disabled,
  width = "24px",
  height = "24px",
  ...rest
}) => {
  const autoId = useId();
  const id = `radio-button=${autoId}`;

  return (
    <Text
      as="label"
      variant="label"
      fontSize={2}
      htmlFor={id}
      key={id}
      display="flex"
      alignItems="center"
      opacity={disabled ? 0.6 : 1}
      cursor={disabled ? "not-allowed" : "pointer"}
    >
      <StyledRadio
        width={width}
        height={height}
        id={id}
        value={value}
        type="radio"
        disabled={disabled}
        {...rest}
      />
      {label}
    </Text>
  );
};

type CSS = CSSProperties;

type RadioGroupProps = {
  picked?: string;
  onChange?: any;
  onClick?: any;
  id?: string;
  name: string;
  options: { label?: string; value: string | number }[];
  space: ResponsiveValue<CSS["margin"]>;
  flexDirection?: ResponsiveValue<CSS["flexDirection"]>;
  disabled?: boolean;
} & InputWrapperProps;

export const RadioGroup = ({
  picked,
  error,
  options,
  space,
  flexDirection,
  id: initialId,
  label,
  status = undefined,
  statusMessage = undefined,
  disabled = false,
  ...rest
}: RadioGroupProps) => {
  const autoId = useId();
  const id = initialId || `radio-group-${autoId}`;

  return (
    <InputWrapper
      status={status}
      statusMessage={statusMessage}
      error={error}
      label={label}
      id={id}
      {...pick(rest)}
    >
      <Stack space={space} flexDirection={flexDirection} role="radiogroup">
        {options.map((option, i) => (
          <Radio
            checked={option.value === picked}
            value={option.value}
            label={option?.label ?? option?.value}
            key={i}
            disabled={disabled}
            {...omit(rest)}
          />
        ))}
      </Stack>
    </InputWrapper>
  );
};
