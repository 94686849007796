import { Box } from '@storyofams/react-ui';
import styled from 'styled-components';

export const NavigationTitle = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: ['flex-start', 'flex-start', 'center'],
  height: ['auto', 'auto', '100%'],
  fontWeight: 'medium',
  width: ['100%', '100%', 'auto'],
  fontSize: 1.75,
  lineHeight: ['normal', 'normal', 'high'],
  fontFamily: 'heading',
})`
  text-transform: uppercase;

  &:hover {
    /* color: ${(p) => p.theme.colors.oldPink}; */
  }
`;
