import { FC } from "react";
import { Box, Flex, SystemProps } from "@storyofams/react-ui";
import { MARK_BOLD } from "storyblok-rich-text-react-renderer";

import { css } from "styled-components";
import { RichText } from "../../RichText";
import { Text } from "../../Text";

interface NavigationCTABannerProps extends SystemProps {
  text?: any;
  bg?: any;
  color?: any;
}

export const NavigationCTABanner: FC<NavigationCTABannerProps> = ({
  text,
  bg,
  color,
}) => {
  return (
    <Box
      position="relative"
      width="100%"
      py={0.75}
      px={[1, 192 as any]}
      backgroundColor={bg || "black"}
      textAlign="center"
      overflow="hidden"
      css={css({ a: { whiteSpace: "nowrap" } })}
    >
      <RichText
        markResolvers={{
          [MARK_BOLD]: (children) => (
            <Text as={"b" as any} fontWeight="medium">
              {children}
            </Text>
          ),
        }}
        fontSize={1.75}
        color={color || "white"}
        text={text}
      />
    </Box>
  );
};
