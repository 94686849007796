import { Flex, Spinner } from "@storyofams/react-ui";
import { useShopify } from "~context";
import {
  CartFragmentFragment,
  CheckoutFragmentFragment,
} from "~lib/shopify/sdk";

import { Divider } from "../Divider";
import { QuantityInput } from "../Forms";
import { LineItem } from "./LineItem";
import { RemoveButton } from "./RemoveButton";

interface CheckoutLineItemProps {
  item: CartFragmentFragment["lines"]["edges"][number]["node"];
  last?: boolean;
  tiered_enabler?: boolean;
  isInModal?: boolean;
  discountAllocations: CartFragmentFragment["discountAllocations"];
}

export const CheckoutLineItem = ({
  item,
  last,
  tiered_enabler,
  isInModal,
  discountAllocations,
}: CheckoutLineItemProps) => {
  const { updatingCart } = useShopify();
  return (
    <>
      <LineItem
        {...item}
        discountAllocations={discountAllocations}
        isInModal={isInModal}
        tiered_enabler={tiered_enabler}
        mb={0}
      >
        <Flex alignItems="center">
          {updatingCart ? (
            <Spinner mr={1} color="grey200"/>
          ) : (
            <RemoveButton
              variant={item}
              display={isInModal ? "none" : ["none", "flex"]}
              mr={1}
            />
          )}

          <QuantityInput item={item} fontSize={isInModal ? 1.75 : [1.75, 2]} />
        </Flex>
      </LineItem>

      {!last && <Divider my={3} color="grey200" />}
    </>
  );
};
