import { memo } from "react";
import { Box, Flex, Icon, Text } from "@storyofams/react-ui";
import Image from "next/image";
import Link from "next/link";
import {
  formatAlgoliaProductsForTracking,
  formatShopifyIdForTracking,
  storeProductClickOrigin,
  track,
} from "~lib";
import { Star } from "~components/common/Icons";

interface ProductProps {
  index?: number;
  product: any;
}
const blockedIds = [
  "diy-no-bs-deodorant",
  "no-bs-deodorant",
  "buttery-lipbalm",
];
export const Product: React.FC<ProductProps> = memo(({ product, index }) => {
  const review =
    product?.meta?.eigenschappen?.product_rating || product?.metafield?.value;
  return (
    <Link prefetch={false} href={`/products/${product?.handle}`}>
      <Flex
        width={"100%"}
        onClick={(e) => {
          storeProductClickOrigin(
            `${product.id}` || formatShopifyIdForTracking(product.id),
            window.location.pathname
          );
          track({
            event: "dl_select_item",
            ecommerce: {
              currencyCode: "EUR",
              click: {
                actionField: { list: window.location.pathname },
                products: product.objectID
                  ? formatAlgoliaProductsForTracking({
                      hits: [product],
                    })
                  : [
                      {
                        name: product.title,
                        id: product.variants?.edges?.[0]?.node?.sku || "",
                        product_id: formatShopifyIdForTracking(product.id),
                        variant_id:
                          formatShopifyIdForTracking(
                            product.variants?.edges?.[0]?.node?.id
                          ) || "",
                        price:
                          product?.priceRange?.minVariantPrice?.amount || "",
                        brand: product.vendor || "",
                        position: Number(index),
                        category: product.productType || "",
                        list: window.location.pathname,
                        variant:
                          formatShopifyIdForTracking(
                            product.variants?.edges?.[0]?.node?.id
                          ) || "",
                      },
                    ],
              },
            },
          });
        }}
        cursor={"pointer"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Flex minWidth={0} width={"100%"}>
          <Box
            border={"1px solid grey200"}
            borderRadius={"8px"}
            width={50}
            height={50}
            position={"relative"}
            mr="12px"
            overflow={"hidden"}
          >
            {(product?.image || product?.smallImages?.edges[0].node.url) && (
              <Image
                layout="fill"
                objectFit="cover"
                quality={50}
                src={
                  blockedIds[0] === product.handle
                    ? "https://cdn.shopify.com/s/files/1/1020/6689/files/NaturalHeroes-Products-vD03-DIYKit-NoBSDeo-2DFront-3KSQ_400x400.png?v=1708878649"
                    : blockedIds[1] === product.handle
                    ? "https://cdn.shopify.com/s/files/1/1020/6689/files/papertube-d5-nobs-deo_scented-freshbalance-30ml-1KSQ-sRGB_400x400.png?v=1708878649"
                    : blockedIds[2] === product.handle
                    ? "https://cdn.shopify.com/s/files/1/1020/6689/files/papertube-buttery-lipbalms_scented-peppermint-10ml-1KSQ-sRGB_400x400.png?v=1708878649"
                    : product?.image || product?.smallImages?.edges[0].node.url
                }
              />
            )}
          </Box>
          <Box flex={1} minWidth={0} width={"100%"}>
            <Flex
              mb={!review && "5px"}
              mt={!review && "5px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text fontFamily={"DINPro"} fontSize={"12px"} color={"#767473"}>
                PRODUCT
              </Text>
              {!!review && review !== "0" && (
                <Box
                  display={"flex"}
                  fontWeight="bold"
                  alignItems="center"
                  lineHeight={1.5}
                >
                  <Text pt="1px" fontSize={2} pl={1}>
                    {review}
                  </Text>
                  <Icon
                    ml={0.5}
                    fontSize={[1.75]}
                    color="oldPink"
                    icon={Star}
                  />
                </Box>
              )}
            </Flex>

            <Text
              fontFamily={"DINPro"}
              fontWeight={700}
              fontSize={"16px"}
              css={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product?.title.toUpperCase()}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Link>
  );
});
