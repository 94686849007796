import { Text } from "../../../components";

export const CartNote = ({ children }) => (
  <Text
    maxWidth={230}
    mx="auto"
    fontSize={[1.25, 1.75]}
    color="grey600"
    textAlign="center"
  >
    {children}
  </Text>
);
