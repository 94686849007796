import { Box, Flex } from '@storyofams/react-ui';
import { PaymentIcon } from '../Layout/PaymentIcon';

export const PaymentOptions = ({ options, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexWrap="wrap"
    flex={1}
    mt={[-1, -2]}
    ml={[-1, -2]}
    {...props}
  >
    {options?.map((icon, i) => (
      <Box key={`icon-${i}`} mt={[1, 2]} ml={[1, 2]}>
        <PaymentIcon icon={icon} />
      </Box>
    ))}
  </Flex>
);
