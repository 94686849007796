import React, { ReactNode } from "react";
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
  DialogOverlayProps,
} from "@reach/dialog";
import { Icon, Close, Flex, SystemProps, Box } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { Heading } from "../../../components";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDrawer = motion(ReachDialogContent);

const Overlay = styled(MotionOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;

  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(29, 29, 27, 0.4);
`;

const Content = styled(MotionDrawer)<
  SystemProps & { children: ReactNode; from: "right" | "left" }
>`
  position: fixed;
  ${(p) => (p.from === "left" ? "left: 0" : "right: 0")};
  top: 0;
  bottom: 0;

  flex: 1;

  width: 90%;
  min-width: 360px;
  max-width: 480px;
  height: 100vh;

  padding: 0;
  margin: 0;

  background: transparent;
`;

const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${(p) => p.theme.colors["white"]};
  z-index: ${(p) => p.theme.zIndices.modal + 1};

  &:after {
    content: "";
    position: fixed;
    top: 0;
    width: 360px;
    height: ${(p) => p.theme.space[4]}px;
    background-color: ${(p) => p.theme.colors["white"]};
  }
`;

const CloseButton = styled(Box).attrs({ as: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;
  height: 100%;

  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.grey400};
    }
  }
`;

interface Props extends DialogOverlayProps, SystemProps {
  isOpen: boolean;
  close(any): void;
  title?: string | ReactNode;
  ariaLabel?: string;
  children?: ReactNode;
  from?: "right" | "left";
}

export const Drawer = ({
  children,
  ariaLabel,
  title,
  isOpen,
  close,
  from = "right",
  bg,
  ...props
}: Props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.24 }}
          {...omit(props)}
        >
          <Content
            aria-label={ariaLabel || "modal"}
            initial={{ x: from === "left" ? "-100%" : "100%" }}
            exit={{ x: from === "left" ? "-100%" : "100%" }}
            animate={{ x: 0 }}
            transition={{ ease: "easeInOut", duration: 0.24 }}
            from={from}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.32 }}
            >
              <Flex
                position="sticky"
                top={0}
                justifyContent="space-between"
                alignItems="center"
                bg="white"
                height="70px"
                flexShrink={0}
                zIndex="modal"
                pl={[2, 3]}
              >
                <Flex flex={1} alignItems="center">
                  {title && (
                    <>
                      {typeof title === "string" ? (
                        <Heading
                          as="h1"
                          variant="h5"
                          flex={1}
                          // css={`
                          //   text-transform: uppercase;
                          // `}
                        >
                          {title}
                        </Heading>
                      ) : (
                        title
                      )}
                    </>
                  )}
                </Flex>

                <CloseButton onClick={close} aria-label="Close modal" ml="auto">
                  <Icon icon={<Close />} fontSize={1.5} color="grey800" />
                </CloseButton>
              </Flex>

              <Box
                height="calc(100% - 70px)"
                width="100%"
                px={[2, 3]}
                {...pick(props)}
              >
                {children}
              </Box>
            </ContentWrapper>
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};
