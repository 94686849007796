import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Icon, Text } from "@storyofams/react-ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { BreadcrumbJsonLd } from "next-seo";
import { ArrowUp, House } from "../Icons";
const colorGradients = {
  Draagoliën: "rgba(234, 200, 181, 0.7)",
  "Etherische olie": "rgba(230, 225, 215, 0.9)",
  Hydrosolen: "rgba(219, 228, 219, 0.5)",
  "(Basis) Zepen": "rgba(238, 210, 203, 0.6)",
  "Klei & Poeders": "rgba(222, 206, 187, 0.7)",
  "Kruiden & Plantpoeders": "rgba(216, 220, 192, 0.5)",
  "Boters & Wassen": "rgba(237, 215, 183, 0.4)",
  Hulpstoffen: "rgba(218, 192, 175, 0.7)",
  overall: "rgba(241, 227, 213, 0.6)",
};
export const Breadcrumbs = React.memo(({ gradientType }: any) => {
  const bg =
    gradientType in colorGradients ? colorGradients[gradientType] : "#EFEAE4";
  const [title, setTitle] = useState("");
  const { asPath } = useRouter();
  useEffect(() => {
    setTitle(
      document
        .querySelector('[data-id="title"]')
        ?.innerHTML.replace(/<\/?[^>]+(>|$)/g, "")
    );
  }, [asPath]);
  const clearPath = asPath.includes("?")
    ? asPath.slice(0, asPath.lastIndexOf("?"))
    : asPath;
  const path = useMemo(() => {
    const res = clearPath
      .split("/")
      .filter((item) => item && item !== "TmF0dXJhbCBIZXJvZXM=");
    if (title) {
      res.pop();
      res.push(title.replace(/&amp;/g, "&"));
    }
    return res;
  }, [asPath, title]);
  const getText = (text) => {
    if (text.includes("-")) {
      const str = text.split("-").join(" ");
      return str[0].toUpperCase() + str.slice(1).replace(/\?.+/, "");
    }
    return text[0].toUpperCase() + text.slice(1).replace(/\?.+/, "");
  };
  const getLink = (link) => {
    const index = asPath.indexOf(link);
    return asPath.slice(0, index + link.length);
  };
  if (!path.length) return null;
  return (
    <>
      <Box
        display={"flex"}
        overflowX="auto"
        bg={"white"}
        opacity={"65%"}
        mb={["10px", "4px"]}
        borderRadius={"md"}
        px={["10px", "15px"]}
        py={"6px"}
        alignItems={"center"}
      >
        <Link prefetch={false} href={"/"}>
          <Icon pb={"1px"} fontSize={"14px"} icon={House} cursor={"pointer"} />
        </Link>
        <Text fontWeight={400} opacity={0.5} mx={1} pt="3px" fontSize={1.25}>
          <Icon transform={"rotate(90deg)"} icon={ArrowUp}></Icon>
        </Text>
        {path.map((item, i) => {
          if (i === path.length - 1)
            return (
              <Text
                fontSize={"14px"}
                key={i}
                fontWeight={400}
                pt={["1px", "2px"]}
                whiteSpace={"nowrap"}
              >
                {getText(item)}
              </Text>
            );
          return (
            <Fragment key={item || "home"}>
              <Link prefetch={false} href={getLink(item)}>
                <Text
                  pt={["1px", "2px"]}
                  whiteSpace={"nowrap"}
                  fontSize={"14px"}
                  fontWeight={400}
                  cursor={"pointer"}
                >
                  {getText(item)}
                </Text>
              </Link>
              {i !== path.length - 1 && (
                <Text
                  opacity={0.5}
                  fontWeight={400}
                  mx={1}
                  pt="5px"
                  fontSize={1.25}
                >
                  <Icon transform={"rotate(90deg)"} icon={ArrowUp}></Icon>
                </Text>
              )}
            </Fragment>
          );
        })}
      </Box>
      <BreadcrumbJsonLd
        itemListElements={path.map((item, index) => ({
          position: index + 1,
          name: getText(item),
          item: `https://www.naturalheroes.nl${getLink(item)}`,
        }))}
      />
    </>
  );
});
