import { useEffect, useMemo, useState } from "react";

import React from "react";
import { Box, Flex } from "@storyofams/react-ui";
import { Icon } from "@storyofams/react-ui";
import Link from "next/link";

import { useQuery } from "react-query";
import { ProductSortKeys } from "shopify.sdk";
import styled from "styled-components";
import { Cross } from "~components/common/Icons";
import { shopifySdk } from "~lib/shopify/client";
import { Divider, Text } from "../../../components";

import { Product } from "./SearchCards/Product";

const trending = [
  {
    href: "/products/all-purpose-balm",
    label: "All Purpose Balm",
    id: "/products/all-purpose-balm",
  },
  { href: "/recipes", label: "DIY Recepten", id: "/recipes" },
  {
    href: "/collections/geurolien",
    label: "Etherische Olie",
    id: "/collections/geurolien",
  },
  {
    href: "/products/vitamine-e-olie",
    label: " Vitamine E",
    id: "/products/vitamine-e-olie",
  },
];
export const SearchItem = styled(Box)`
  :hover {
    background: #f5f5f5;
  }
`;
export const SearchWindow = ({
  close,
  relatedSearches,
  setRelatedSearches,
}) => {
  const [pickedId, setPickedId] = useState("");
  const { data: popularProducts } = useQuery(
    ["popularProducts"],
    () =>
      shopifySdk.products({
        first: 3,
        sortKey: ProductSortKeys.BestSelling,
        query: "available_for_sale:true",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: requestedProducts } = useQuery(
    ["dadaProducts"],
    () =>
      shopifySdk.products({
        first: 3,
        query: "tag:include-in-search-dropdown",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const removeRelatedSearch = (str) => {
    setRelatedSearches((searches) => {
      const res = searches.filter((res) => str !== res);
      localStorage.setItem("searchStrings", JSON.stringify(res));
      return res;
    });
  };
  let allResults: any[] = useMemo(() => {
    setPickedId("");
    return [
      ...((relatedSearches &&
        relatedSearches.map((item) => ({ name: item, id: item }))) ||
        []),
      ...trending,
      ...((popularProducts &&
        requestedProducts &&
        [
          ...popularProducts?.products?.edges.slice(
            0,
            popularProducts?.products?.edges.length -
              requestedProducts?.products?.edges.length
          ),
          ...requestedProducts?.products?.edges,
        ].map((item) => ({
          ...item,
          id: item.cursor,
        }))) ||
        []),
    ];
  }, [relatedSearches, trending, popularProducts, requestedProducts]);
  const handler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (pickedId) {
        const res = allResults.find(({ id }) => id === pickedId);
        if (res.name) window.location.href = `/search?query=${res.name}`;
        if (res.label) window.location.href = res.href;
        if (res.cursor) window.location.href = `/products/${res.node.handle}`;
      } else {
        window.location.href = `/search`;
      }
      close();
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (!pickedId) setPickedId(allResults[0]?.id);
      else {
        const index = allResults.findIndex(({ id }) => id === pickedId);
        if (index + 1 >= allResults.length) setPickedId(allResults[0]?.id);
        else setPickedId(allResults[index + 1].id);
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (!pickedId) setPickedId(allResults[allResults.length - 1]?.id);
      else {
        const index = allResults.findIndex(({ id }) => id === pickedId);
        if (index <= 0) setPickedId(allResults[allResults.length - 1].id);
        else setPickedId(allResults[index - 1].id);
      }
    }
  };

  useEffect(() => {
    document.addEventListener<"keydown">("keydown", handler, false);

    return () => {
      document.removeEventListener<"keydown">("keydown", handler, false);
    };
  }, [handler]);
  return (
    <Box bg="white" mt="10px" borderRadius={"md"} py={["8px", "20px"]}>
      {allResults.map((item, i) => {
        if (item?.name)
          return (
            <Box id={item.id + i}>
              {i === 0 && (
                <Text
                  px={["15px", "20px"]}
                  fontWeight={500}
                  fontSize={"14px"}
                  mb="3px"
                >
                  Eerder gezocht
                </Text>
              )}
              <SearchItem
                px={["15px", "20px"]}
                bg={pickedId === item.id && "#f5f5f5"}
              >
                <Flex mb="3px" justifyContent={"space-between"}>
                  <Link prefetch={false} href={`/search?query=${item.name}`}>
                    <Box cursor="pointer" width={"100%"}>
                      <Text fontSize={"16px"}>{item.name}</Text>
                    </Box>
                  </Link>
                  <Icon
                    cursor={"pointer"}
                    onClick={() => removeRelatedSearch(item.name)}
                    icon={Cross}
                  />
                </Flex>
              </SearchItem>
              {relatedSearches && relatedSearches.length - 1 === i && (
                <Divider my="10px" px={["15px", "20px"]} />
              )}
            </Box>
          );
        if (item?.href)
          return (
            <Box id={item.id + i}>
              {item.href === trending[0].href && (
                <Box>
                  <Text
                    px={["15px", "20px"]}
                    fontWeight={500}
                    mb="3px"
                    fontSize={"14px"}
                  >
                    Trending
                  </Text>
                </Box>
              )}
              <Link href={item.href}>
                <SearchItem
                  px={["15px", "20px"]}
                  bg={pickedId === item.id && "#f5f5f5"}
                  onClick={close}
                >
                  <Text mb="3px" cursor={"pointer"}>
                    {item.label}
                  </Text>
                </SearchItem>
              </Link>
              {item.href === trending[trending.length - 1].href && (
                <Divider px={["15px", "20px"]} my="10px" />
              )}
            </Box>
          );
        else
          return (
            <Box id={item.id + i}>
              {popularProducts?.products?.edges[0].cursor === item.cursor && (
                <Text
                  px={["15px", "20px"]}
                  fontWeight={500}
                  mb="10px"
                  fontSize={"14px"}
                >
                  Populaire producten
                </Text>
              )}
              <Box>
                <SearchItem
                  px={["15px", "20px"]}
                  py={"5px"}
                  bg={pickedId === item.id && "#f5f5f5"}
                  onClick={close}
                >
                  <Product product={item.node} />
                </SearchItem>
              </Box>
            </Box>
          );
      })}
    </Box>
  );
};
