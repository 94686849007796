import { Flex, Box, SystemProps } from "@storyofams/react-ui";
import { CartNote, PaymentOptions } from "../../../components";

import { useShopify } from "../../../context";

import { shopifyToIntlPrice } from "../../../lib/shopify/utils";
import { CartItem, FooterItem } from "../../../lib/storyblok/sdk";

import { Button } from "../Button";
import { Divider } from "../Divider";
import { LineItems } from "./LineItems";

export const CartOverlay = ({
  paymentOptions,
  cartNote,
  ...props
}: {
  paymentOptions: FooterItem["content"]["payment_options"];
  cartNote: CartItem["content"]["payment_note"];
} & SystemProps) => {
  const { cart, toggleCartOverlay } = useShopify();

  return (
    <Flex
      flexDirection="column"
      height="100%"
      pt={3}
      {...props}
      position="relative"
    >
      {cart?.cost?.totalAmount && (
        <Box flex={1} mb={[5, 8]} px={[2, 3]}>
          <LineItems isInModal />
        </Box>
      )}

      <Box
        position="sticky"
        bottom={0}
        left={0}
        bg="white"
        p={[2, 3]}
        boxShadow="0px -4px 10px rgba(0, 0, 0, 0.03)"
      >
        <PaymentOptions options={paymentOptions} justifyContent="center" />
        <Divider my={[2, 3]} />

        <Flex flexDirection="column">
          {cart?.cost?.totalAmount && (
            <Button
              variant="primary"
              href="/cart"
              onClick={() => toggleCartOverlay(false)}
              mb={[2, 2.25]}
              width="100%"
            >
              Afrekenen - {shopifyToIntlPrice(cart?.cost?.totalAmount)}
            </Button>
          )}

          <CartNote>{cartNote}</CartNote>
        </Flex>
      </Box>
    </Flex>
  );
};
