import { useState, useEffect, useMemo, FC } from "react";
import { Box, Stack, Grid, css, Icon, Text, Flex } from "@storyofams/react-ui";

import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import { createClient } from "@supabase/supabase-js";
import Image from "next/image";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { useQuery } from "react-query";
import { ArrowRight, ArrowUp, Star } from "~components/common/Icons";
import { Button, Divider } from "../../../../components";
import { getLinkProps } from "../../../../lib";
import { NavigationLinkNew } from "./NavigationLinkNew";

export const RecipesMenu = ({ megaData, close }) => {
  const posthog = usePostHog();
  const divideLink = useMemo(() => {
    const leftColumn = megaData?.items?.slice(
      0,
      Math.ceil(megaData?.items?.length / 2)
    );
    const rightColumn = megaData?.items?.slice(
      Math.ceil(megaData?.items.length / 2),
      megaData?.items.length
    );
    return { leftColumn, rightColumn };
  }, [megaData]);
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeItems = async (): Promise<any> => {
    const ids = [
      ...megaData.popular_recipes.map((item) => item.title),
      megaData.recipe_card,
    ];
    try {
      //@ts-ignore
      let query = supabase
        .from("recipes_with_rating")
        .select(
          "*, contents_uom(text), difficulty(text), makes_uom(text), summary(text), title(text), recipes_ingredients(title)"
        )
        .in("slug", ids);
      const { data } = await query;
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems } = useQuery(["getRecipeItems"], getRecipeItems, {
    enabled: true,
  });
  const recipes = useMemo(() => {
    let card;
    let items;
    if (recipeItems?.length) {
      card = recipeItems.find(({ slug }) => slug === megaData.recipe_card);
      items = recipeItems.filter(({ slug }) => slug !== megaData.recipe_card);
    }
    return { card, items };
  }, [recipeItems]);
  return (
    <Box
      top={50}
      position={"absolute"}
      width={"100%"}
      bg="white"
      borderRadius={"6px"}
    >
      <Stack space={"30px"} display={"flex"} width={"100%"} p={"20px"}>
        <Box width="35%">
          <Text
            mb={"10px"}
            fontFamily="DINPro"
            fontWeight={500}
            fontSize="14px"
          >
            CATEGORIEËN
          </Text>
          <Box display="flex" width={"100%"}>
            <Flex width={"100%"}>
              <Box display="flex" mr="50px" flexDirection="column">
                {divideLink.leftColumn &&
                  divideLink.leftColumn.map((item) => (
                    <Box mb={"5px"} key={item.title}>
                      <NavigationLinkNew
                        textAlign="left"
                        justifyContent="flex-start"
                        link={getLinkProps(item.url)}
                        onClick={() => {
                          close();
                          // posthog.capture("Clicked Menu", {
                          //   tab: "Recepten",
                          //   item: item.title,
                          // });
                        }}
                      >
                        {item.title}
                      </NavigationLinkNew>
                    </Box>
                  ))}
              </Box>
              <Box display="flex" flexDirection="column">
                {divideLink.rightColumn &&
                  divideLink.rightColumn.map((item) => (
                    <Box mb={"5px"} key={item.title} width="max-content">
                      <NavigationLinkNew
                        textAlign="left"
                        justifyContent="flex-start"
                        link={getLinkProps(item.url)}
                        onClick={() => {
                          close();
                          // posthog.capture("Clicked Menu", {
                          //   tab: "Recepten",
                          //   item: item.title,
                          // });
                        }}
                      >
                        {item.title}
                      </NavigationLinkNew>
                    </Box>
                  ))}
              </Box>
            </Flex>
          </Box>
          <Box
            display="flex"
            as="a"
            alignItems={"center"}
            height={"40px"}
            mt="10px"
            border="1px solid black"
            borderRadius={"md"}
            px={"20px"}
            href={"/recipes"}
            width="max-content"
            onClick={() => {
              close();
              // posthog.capture("Clicked Menu", {
              //   tab: "Recepten",
              //   item: megaData?.button,
              // });
            }}
          >
            <Text fontWeight={500} fontSize="16px">
              {megaData?.button}
            </Text>
            <Icon
              fontSize={"12px"}
              transform="rotate(90deg)"
              icon={ArrowUp}
              ml="7px"
            />
          </Box>
        </Box>

        <Box width="35%">
          {recipes.card && (
            <>
              <Text
                mb={"15px"}
                fontFamily="DINPro"
                fontWeight={500}
                css={{ textTransform: "uppercase" }}
                fontSize="14px"
              >
                Uitgelicht
              </Text>
              <Link prefetch={false} href={`/recipes/${recipes.card.slug}`}>
                <Box
                  p="25px"
                  border={"1px solid #E6E0D7"}
                  borderRadius={"md"}
                  cursor={"pointer"}
                  onClick={() => {
                    close();
                    // posthog.capture("Clicked Menu", {
                    //   tab: "Recepten",
                    //   item: "Recipe big card",
                    // });
                  }}
                >
                  <Box
                    cursor="pointer"
                    height={217}
                    position={"relative"}
                    css={css({
                      img: {
                        borderRadius: "md",
                      },
                    })}
                  >
                    <Image
                      src={recipes?.card?.image + "/m/350x350"}
                      layout="fill"
                      objectFit={"cover"}
                    />
                  </Box>
                  <Flex mt="15px" justifyContent={"space-between"}>
                    <Text
                      fontFamily="DINPro"
                      color="grey600"
                      fontSize={"14px"}
                      css={{ textTransform: "uppercase" }}
                    >
                      {"Recept"}
                    </Text>
                    {!!recipes?.card?.rating && (
                      <Box
                        display={"flex"}
                        fontWeight="bold"
                        alignItems="center"
                        lineHeight={1.5}
                      >
                        <Text fontSize={"14px"} pl={1} pt="1px">
                          {recipes?.card?.rating}
                        </Text>
                        <Icon
                          ml={0.5}
                          fontSize={"14px"}
                          color="oldPink"
                          icon={Star}
                          pb={"1px"}
                        />
                      </Box>
                    )}
                  </Flex>
                  <Text
                    mt="11px"
                    fontFamily="DINPro"
                    fontWeight={700}
                    lineHeight={"20px"}
                    fontSize={"18px"}
                    css={{ textTransform: "uppercase" }}
                  >
                    {recipes?.card?.title?.text}
                  </Text>
                </Box>
              </Link>
            </>
          )}
        </Box>
        <Box width="30%">
          <Text
            mb={"15px"}
            fontFamily="DINPro"
            fontWeight={500}
            css={{ textTransform: "uppercase" }}
            fontSize="14px"
          >
            Populaire recepten
          </Text>
          {recipes.items &&
            recipes.items.map((recipe, i) => {
              return (
                <Link
                  prefetch={false}
                  href={`/recipes/${recipe.slug}`}
                  key={recipe.slug}
                >
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      close();
                      // posthog.capture("Clicked Menu", {
                      //   tab: "Recepten",
                      //   item: recipe?.title.text,
                      // });
                    }}
                  >
                    <Box display="flex">
                      <Box
                        css={css({
                          img: {
                            borderRadius: "md",
                          },
                        })}
                      >
                        {recipe?.image && (
                          <Image
                            src={recipe.image}
                            width={75}
                            height={75}
                            objectFit="cover"
                          />
                        )}
                      </Box>

                      <Flex
                        flexDirection={"column"}
                        justifyContent={"center"}
                        ml={1}
                        width="100%"
                      >
                        <Text
                          fontFamily="DINPro"
                          color="grey600"
                          fontSize={"14px"}
                          css={{ textTransform: "uppercase" }}
                        >
                          {"Recept"}
                        </Text>
                        <Text
                          fontFamily="DINPro"
                          fontWeight={500}
                          fontSize={"16px"}
                          lineHeight={"19px"}
                          css={{ textTransform: "uppercase" }}
                        >
                          {recipe?.title.text}
                        </Text>
                      </Flex>
                    </Box>
                    {i !== recipes?.items?.length - 1 && (
                      <Box borderTop="1px solid #E6E0D7" my={"10px"} />
                    )}
                  </Box>
                </Link>
              );
            })}
        </Box>
      </Stack>
    </Box>
  );
};
