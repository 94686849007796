import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Text } from "@storyofams/react-ui";
import axios from "axios";
import Image from "next/image";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { getLinkProps, showToast } from "../../../lib";
import { Button } from "../Button";
import { Input } from "../Input";
import { Modal } from "../Modal";

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
});
export const EmailPopup = ({
  isOpen,
  close,
  isDisabled = false,
  isRedirected = false,
  redirectUrl = "",
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ email: string }>({ resolver: yupResolver(schema) as any });
  const onSubmit = async ({ email }) => {
    try {
      let { data } = await axios.post(
        `/api/klaviyo/sendEmail?list=${isDisabled && "UsEVBU"}`,
        { email }
      );
      if (data?.message && data.message.includes(409)) {
        showToast("Gebruiker bestaat al", "error");
      } else if (data.name && data.name === "Error") {
        showToast("Error", "error");
      } else {
        showToast("Bevestiging is verzonden naar je e-mail!", "success");
        if (isDisabled) window.location.href = `/bedankt-green-friday-2023`;
        if (!isDisabled) close();
        reset({});
      }
      if (isRedirected) {
        window.location.href = getLinkProps(redirectUrl);
      }
    } catch (err) {
      if (err.message.includes("409"))
        showToast("Gebruiker bestaat al", "error");
      else showToast(err?.message, "error");
    }
  };
  return (
    <Modal
      isDisabled={isDisabled}
      overflow="visible"
      isOpen={isOpen}
      close={() => {
        if (!isDisabled) close();
        reset();
      }}
    >
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          mx="auto"
          top={-8}
          left={0}
          right={0}
          width={130}
          height={130}
          position={"absolute"}
        >
          <Image
            layout="fill"
            src={
              "https://a.storyblok.com/f/125270/301x300/03328f9a0b/group-26.png"
            }
          />
        </Box>
        <Box textAlign={"center"}>
          {/* {content?.image?.filename && (
            <Box
              width={"100%"}
              height={"200px"}
              mt={3}
              position={"relative"}
              css={css({
                img: {
                  borderRadius: "md",
                },
              })}
            >
              <Image
                objectFit="cover"
                layout="fill"
                src={content?.image.filename}
              />
            </Box>
          )} */}
          <Text
            fontFamily={"DINPro"}
            fontWeight={700}
            fontSize={"28px"}
            mb={"20px"}
            mt="30px"
          >
            {isDisabled ? "GREEN FRIDAY 2023" : "Blijf op de hoogte"}
          </Text>

          {/* <RichText text={content?.body} px="25px" mb={"40px"} /> */}
          <Text fontSize={"18px"} lineHeight={"28px"} px="25px" mb={"40px"}>
            {isDisabled
              ? "Schrijf je nu in en ontvang een email zodra we live zijn!"
              : "We delen onze tips, acties en artikelen over natuurlijke cosmetica en DIY'en met je"}
          </Text>
          <Input
            width={"100%"}
            mb="15px"
            placeholder="Jouw e-mailadres"
            required
            type="email"
            {...register("email")}
            error={errors?.email?.message}
          />
          <Button
            width={"100%"}
            height={"48px"}
            type="submit"
            isLoading={isSubmitting}
          >
            Meld je aan
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
