import { useState, useEffect, useMemo, FC } from "react";
import { Box, Stack, Grid, css, Icon, Text, Flex } from "@storyofams/react-ui";

import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import Image from "next/image";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { ArrowRight, ArrowUp } from "~components/common/Icons";
import { Button, Divider } from "../../../../components";
import { getLinkProps } from "../../../../lib";
import { NavigationLinkNew } from "./NavigationLinkNew";

export interface NavigationMegaSubLinksNewProps {
  megaData?: {
    component?: string;
    mega_link_item_3columns_items?: {
      title: string;
      url: { [key: string]: string };
    }[];
    mega_link_item_3columns_content?: any;
    mega_link_item_3columns_banner_url?: { [key: string]: string };
    mega_link_item_3columns_label?: string;
    mega_link_item_3columns_banner?: {
      filename: string;
    };
    mega_link_item_3columns_url?: { [key: string]: string };
    mega_link_item_group_items?: {
      column_menu_col?: number;
      Label: string;
      column_menu_items: any;
      column_menu_url: { [key: string]: string };
    }[];
    mega_link_item_group_url?: { [key: string]: string };
    mega_link_item_group_label?: string;
    mega_link_item_3columns_items_new?: any;
    items?: {
      _uid: string;
      title: string;
      url: { [key: string]: string };
    }[];
  };
  close: () => void;
}

export const NavigationMegaSubLinksNew: FC<NavigationMegaSubLinksNewProps> = ({
  megaData,
  close,
}) => {
  const posthog = usePostHog();
  if (megaData.component === "mega_link_item_3columns")
    return (
      <Stack space={"30px"} display={"flex"} width={"100%"} p={"20px"}>
        <Box width="26%">
          <Box display="flex" width={"100%"}>
            <Box display="flex" width={"100%"} flexDirection="column">
              {megaData?.mega_link_item_3columns_items &&
                megaData?.mega_link_item_3columns_items.map((item) => (
                  <Box mb={"8px"} key={item.title} width="max-content">
                    <NavigationLinkNew
                      textAlign="left"
                      justifyContent="flex-start"
                      link={getLinkProps(item.url)}
                      onClick={() => {
                        close();
                        // posthog.capture("Clicked Menu", {
                        //   tab: "Shop",
                        //   item:
                        //     megaData.mega_link_item_3columns_label ||
                        //     megaData.mega_link_item_group_label,
                        //   subItem: item.title,
                        // });
                      }}
                    >
                      {item.title}
                    </NavigationLinkNew>
                  </Box>
                ))}
            </Box>
          </Box>
          <Button
            mt="3px"
            variant="unstyled"
            display="flex"
            as="a"
            href={getLinkProps(megaData?.mega_link_item_3columns_url)}
            width="max-content"
            onClick={() => {
              close();
              // posthog.capture("Clicked Menu", {
              //   tab: "Shop",
              //   item:
              //     megaData.mega_link_item_3columns_label ||
              //     megaData.mega_link_item_group_label,
              //   subItem: `ALLE ${megaData?.mega_link_item_3columns_label}`,
              // });
            }}
          >
            <Icon
              pt="1px"
              fontSize={"10px"}
              transform="rotate(90deg)"
              icon={ArrowUp}
              mr="7px"
              opacity={0.3}
            />
            <Text fontFamily="DINPro" fontWeight={500} fontSize="14px">
              ALLE {megaData?.mega_link_item_3columns_label?.toUpperCase()}
            </Text>
          </Button>
        </Box>

        <Box width="40%" height="225px">
          {megaData?.mega_link_item_3columns_items_new &&
            megaData.mega_link_item_3columns_items_new.map((blog, i) => {
              return (
                <Link
                  onClick={() => {
                    close();
                    // posthog.capture("Clicked Menu", {
                    //   tab: "Shop",
                    //   item:
                    //     megaData.mega_link_item_3columns_label ||
                    //     megaData.mega_link_item_group_label,
                    //   subItem: blog?.title,
                    // });
                  }}
                  prefetch={false}
                  href={
                    blog?.url.linktype === "story"
                      ? "https://www.naturalheroes.nl/".concat(
                          blog?.url?.cached_url
                        )
                      : blog?.url?.url
                  }
                  key={blog.title}
                >
                  <Box cursor="pointer">
                    <Box display="flex">
                      <Box
                        css={css({
                          img: {
                            borderRadius: "md",
                          },
                        })}
                      >
                        <ToolkitImage
                          src={blog.image?.filename}
                          width={60}
                          height={60}
                          fluid={150}
                        />
                      </Box>

                      <Flex
                        flexDirection={"column"}
                        justifyContent={"center"}
                        ml={1}
                        width="100%"
                      >
                        <Text
                          fontFamily="DINPro"
                          color="grey600"
                          fontSize={"14px"}
                          css={{ textTransform: "uppercase" }}
                        >
                          {blog?.type}
                        </Text>
                        <Text
                          fontFamily="DINPro"
                          fontWeight={500}
                          fontSize={"16px"}
                          lineHeight={"19px"}
                          css={{ textTransform: "uppercase" }}
                        >
                          {blog?.title}
                        </Text>
                      </Flex>
                    </Box>
                    {i === 0 && <Box borderTop="1px solid #E6E0D7" my={2} />}
                  </Box>
                </Link>
              );
            })}
        </Box>
        <Box width="33%" height="225px">
          {megaData?.mega_link_item_3columns_banner_url && (
            <Link
              href={getLinkProps(megaData?.mega_link_item_3columns_banner_url)}
              prefetch={false}
              onClick={() => {
                close();
                // posthog.capture("Clicked Menu", {
                //   tab: "Shop",
                //   item:
                //     megaData.mega_link_item_3columns_label ||
                //     megaData.mega_link_item_group_label,
                //   subItem: "banner",
                // });
              }}
            >
              <Box
                cursor="pointer"
                height={"100%"}
                position={"relative"}
                css={css({
                  img: {
                    borderRadius: "md",
                  },
                })}
              >
                <Image
                  src={
                    megaData?.mega_link_item_3columns_banner?.filename +
                    "/m/450x450"
                  }
                  layout="fill"
                  objectFit={"cover"}
                />
              </Box>
            </Link>
          )}
        </Box>
      </Stack>
    );
  else
    return (
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        p={"20px"}
      >
        {new Array(4).fill(1).map((item, i) => (
          <Box key={i} display="flex" flexDirection="column">
            {megaData.mega_link_item_group_items
              .filter((item) => Number(item.column_menu_col) === i + 1)
              .map((col_item, idx, arr) => (
                <Box
                  mb={"20px"}
                  pb="10px"
                  key={col_item.Label}
                  borderBottom={arr.length - 1 !== idx && "1px solid grey200"}
                >
                  <Button
                    as="a"
                    href={getLinkProps(col_item?.column_menu_url)}
                    onClick={() => {
                      close();
                      // posthog.capture("Clicked Menu", {
                      //   tab: "Shop",
                      //   item:
                      //     megaData.mega_link_item_3columns_label ||
                      //     megaData.mega_link_item_group_label,
                      //   subItem: col_item?.Label,
                      // });
                    }}
                    variant="unstyled"
                    width="max-content"
                  >
                    <Text
                      mb={"10px"}
                      fontFamily="DINPro"
                      fontWeight={500}
                      fontSize="14px"
                    >
                      {col_item?.Label.toUpperCase()}
                    </Text>
                  </Button>
                  {col_item.column_menu_items.map((item, i) => (
                    <Box mb={"7px"} width="max-content" key={item._uid}>
                      <NavigationLinkNew
                        textAlign="left"
                        onClick={() => {
                          close();
                          // posthog.capture("Clicked Menu", {
                          //   tab: "Shop",
                          //   item:
                          //     megaData.mega_link_item_3columns_label ||
                          //     megaData.mega_link_item_group_label,
                          //   subItem: item?.title || item?.text,
                          // });
                        }}
                        justifyContent="flex-start"
                        link={getLinkProps(item.url)}
                      >
                        {item?.title || item?.text}
                      </NavigationLinkNew>
                    </Box>
                  ))}
                </Box>
              ))}
          </Box>
        ))}
      </Box>
    );
};
