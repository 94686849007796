import { ReactNode } from 'react';
import { SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';

import { Text } from '../../Text';

interface BadgeProps extends SystemProps {
  children: ReactNode;
}

const Wrapper = styled(Text)`
  font-weight: bold;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const Badge = ({ children, ...props }: BadgeProps) => (
  <Wrapper backgroundColor="oldPink" color="grey800" {...props}>
    {children}
  </Wrapper>
);
