import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import React, { ReactNode } from "react";
import { DialogContent as ReachDialogContent } from "@reach/dialog";
import { Box, Flex, css } from "@storyofams/react-ui";
import { Icon } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";
import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import qs from "qs";
import { useQuery } from "react-query";
import { ProductSortKeys } from "shopify.sdk";
import styled from "styled-components";
import { useShopify } from "~context";
import { Cross } from "~components/common/Icons";
import { SearchBar, StyledInput } from "~components/search/Searchbar";
import useMediaQuery from "~hooks/useMediaQuery";
import { shopifySdk } from "~lib/shopify/client";
import { storyblokSdk } from "~lib/storyblok/client";
import { Text } from "../../../components";
import { Article } from "./SearchCards/Article";
import { Product } from "./SearchCards/Product";
import { SearchWindow } from "./SearchWindows";
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  // @media (max-width: ${(p) => p.theme.breakpoints.md}) {
  //   padding: ${(p) => p.theme.space[4]}px ${(p) => p.theme.space[2]}px 92px;
  // }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const DialogContent = styled(MotionDialog)<{
  children?: ReactNode;
}>`
  position: relative;
  flex: 1;
  padding: 0;
  margin: 0;
  background: transparent;
  width: 100%;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    max-width: 400px;
  }
  @media (max-width: 768px) {
    top: 0;
    width: 100%;
    max-height: none;
    width: 100%;
  }
`;

const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 384px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  @media (max-width: 768px) {
    border-radius: 0;
    max-width: 100%;
    max-height: none;
    width: 100%;
    height: 100%;
  }
`;

export const SearchItem = styled(Box)`
  :hover {
    background: #f5f5f5;
  }
`;
interface ResultsProps {
  setRelatedSearches?: any;
  blogItems?: any;
  recipeItems?: any;
  products?: any;
  close?: () => void;
  searchState: any;
}
interface SearchResultsProps {
  close?: () => void;
  isSrcolled?: boolean;
}
const urlToSearchState = (path) => qs.parse(path?.split("?")?.[1]);

export const SearchResults = ({ close, isSrcolled }: SearchResultsProps) => {
  const [relatedSearches, setRelatedSearches] = useState([]);
  const router = useRouter();
  const [searchState, setSearchState] = useState(
    urlToSearchState(router.asPath)
  );
  useEffect(() => {
    const searchStrings = localStorage.getItem("searchStrings");
    if (searchStrings) setRelatedSearches(JSON.parse(searchStrings));
  }, []);
  const { data: blogItems } = useQuery(
    ["searchBlogItems", { q: searchState.query }],
    () =>
      storyblokSdk
        .searchBlogItems({
          searchTerm: (searchState.query as string) || "",
          perPage: 2,
        })
        .then((res) => res.BlogItems.items)
  );
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeItems = async (): Promise<any> => {
    try {
      //@ts-ignore
      let query = supabase
        .from("recipes_with_rating")
        .select("*, title(text)")
        .range(0, 1)
        .eq("published", true);
      if (searchState.query) {
        query = query.or(
          `title_text.ilike.%${searchState.query}%,description_text.ilike.%${searchState.query}%,ingredients.ilike.%${searchState.query}%`
        );
      }
      const { data } = await query;
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems } = useQuery(
    ["getRecipeItems", { q: searchState.query }],
    () => getRecipeItems()
  );
  const { data: products } = useQuery(
    ["searchProducts", searchState.query],
    async () => {
      const result = await shopifySdk.search({
        first: 3,
        query: String(searchState.query || ""),
        sortKey: ProductSortKeys.Relevance,
        // productFilters: [{available: true}]
        productFilters: [
          {
            productMetafield: {
              namespace: "eigenschappen",
              key: "hide",
              value: "false",
            },
          },
          {
            productMetafield: {
              namespace: "eigenschappen",
              key: "parfumvrij",
              value: "false",
            },
          },
        ],
      });
      return result.search.edges.map((item) => item.node);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    const nextSearchState = urlToSearchState(router.asPath);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [router.asPath]);
  return (
    <DialogOverlay onClick={close}>
      <DialogContent
        aria-label={"modal"}
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
      >
        <ContentWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          className={"active"}
        >
          <Box
            bg={["white", "transparent"]}
            height={["100vh", "auto"]}
            mt={[0, isSrcolled ? "20px" : "88px"]}
            transition={"margin-top 0.2s"}
          >
            <Flex
              minWidth={["100%", "384px", "384px", "384px"]}
              width={["100%", "auto"]}
              px={["10px", 0]}
              py={["5px", 0]}
              transition={"top 0.2s"}
              flexDirection={"column"}
              onClick={(e) => e.stopPropagation()}
              css={css({
                input: { minHeight: "40px", py: "9px" },
                svg: { width: "16px" },
              })}
            >
              <Flex>
                <SearchBar />
                <Box
                  cursor={"pointer"}
                  onClick={close}
                  pt="8px"
                  pr="3px"
                  display={["block", "none", "none", "none"]}
                  ml="17px"
                >
                  <Icon pt="1px" fontSize={"22px"} icon={Cross}></Icon>
                </Box>
              </Flex>
            </Flex>
            {!searchState.query ? (
              <SearchWindow
                close={close}
                relatedSearches={relatedSearches}
                setRelatedSearches={setRelatedSearches}
              />
            ) : (
              <Box
                bg="white"
                mt="10px"
                borderRadius={"md"}
                py={["8px", "20px"]}
              >
                <ProductResults
                  products={products}
                  searchState={searchState}
                  setRelatedSearches={setRelatedSearches}
                  close={close}
                  recipeItems={recipeItems}
                  blogItems={blogItems}
                />
              </Box>
            )}
          </Box>
        </ContentWrapper>
      </DialogContent>
    </DialogOverlay>
  );
};

const ProductResults = ({
  setRelatedSearches,
  recipeItems,
  blogItems,
  close,
  products,
  searchState,
}: ResultsProps) => {
  const [pickedId, setPickedId] = useState("");
  const posthog = usePostHog();
  const { query } = useRouter();

  let allResults = useMemo(() => {
    const suggestions = [{ id: searchState.query, label: searchState.query }];
    setPickedId("");
    return [
      ...(suggestions || []),
      ...(products || []),
      ...(recipeItems || []),
      ...(blogItems || []),
    ];
  }, [products, recipeItems, blogItems]);
  const searchHandler = () => {
    setRelatedSearches((searches) => {
      const pickedItem = allResults.find(({ id }) => id === pickedId);
      if (searches && searches.includes(query.query)) return;
      const res = [
        ...searches,
        pickedItem?.popularity ? pickedItem?.query : query?.query,
      ];
      if (res.length > 2) {
        res.shift();
        localStorage.setItem("searchStrings", JSON.stringify(res));
        return res;
      }
      localStorage.setItem("searchStrings", JSON.stringify(res));
      return res;
    });
    posthog.capture("Searched Event");
    close();
  };
  const handler = (event) => {
    if (event.key === "Enter") {
      if (pickedId) {
        const res = allResults.find(({ id }) => id === pickedId);
        if (res?.label) window.location.href = `/search?query=${res?.label}`;
        else if (res.handle) {
          window.location.href = `/products/${res.handle}`;
        } else if (res.author) window.location.href = `/recipes/${res.slug}`;
        else if (res.uuid) window.location.href = `/blog/${res.slug}`;
      } else {
        window.location.href = `/search?query=${query.query}`;
      }
      searchHandler();
      posthog.capture("Searched Event");
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (!pickedId) setPickedId(allResults[0]?.id);
      else {
        const index = allResults.findIndex(({ id }) => id === pickedId);
        const nextId = allResults[index + 1]?.id;
        if (!nextId) setPickedId(allResults[0].id);
        else setPickedId(nextId);
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (!pickedId) setPickedId(allResults[allResults.length - 1]?.id);
      else {
        const index = allResults.findIndex(({ id }) => id === pickedId);
        const nextId = allResults[index - 1]?.id;
        if (!nextId) setPickedId(allResults[allResults.length - 1].id);
        else setPickedId(nextId);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handler, false);

    return () => {
      document.removeEventListener("keydown", handler, false);
    };
  }, [handler]);
  return (
    <Box>
      {allResults.map((item, i) => {
        if (item.label) {
          return (
            <Box key={item + i}>
              <Link prefetch={false} href={`/search?query=${item.id}`}>
                <SearchItem
                  px={["15px", "20px"]}
                  bg={pickedId === item.id && "#f5f5f5"}
                >
                  <Text cursor={"pointer"}>{item.label}</Text>
                </SearchItem>
              </Link>
            </Box>
          );
        }
        if (item.handle)
          return (
            <Fragment key={item.handle + i}>
              {!!products.length && item.id === products[0].id && (
                <Box
                  fontSize={"13px"}
                  fontWeight={600}
                  width={"100%"}
                  height={"32px"}
                  bg="#FCF9F7"
                  my="10px"
                >
                  <Text pt="5px" px={["15px", "20px"]}>
                    PRODUCTEN
                  </Text>
                </Box>
              )}
              <SearchItem
                bg={pickedId === item.id && "#f5f5f5"}
                px={["15px", "20px"]}
                py={"5px"}
                onClick={searchHandler}
              >
                <Product index={i} product={item} />
              </SearchItem>
            </Fragment>
          );
        if ("author" in item)
          return (
            <Fragment key={item.id}>
              {item.id === recipeItems[0]?.id && (
                <Box
                  fontSize={"13px"}
                  fontWeight={600}
                  height={"32px"}
                  bg="#FCF9F7"
                  my="10px"
                >
                  <Text pt="5px" px={["15px", "20px"]}>
                    RECEPTEN
                  </Text>
                </Box>
              )}
              <SearchItem
                bg={pickedId === item.id && "#f5f5f5"}
                px={["15px", "20px"]}
                py={"5px"}
                onClick={searchHandler}
              >
                <Article isWindow article={item} />
              </SearchItem>
            </Fragment>
          );
        else
          return (
            <Fragment key={item.id}>
              {blogItems && item?.id === blogItems[0]?.id && (
                <Box
                  fontSize={"13px"}
                  fontWeight={600}
                  height={"32px"}
                  bg="#FCF9F7"
                  my="10px"
                >
                  <Text pt="5px" px={["15px", "20px"]}>
                    BLOGS
                  </Text>
                </Box>
              )}
              <SearchItem
                bg={pickedId === item?.id && "#f5f5f5"}
                px={["15px", "20px"]}
                py={"5px"}
                onClick={searchHandler}
              >
                <Article isWindow article={item} />
              </SearchItem>
            </Fragment>
          );
      })}
    </Box>
  );
};
