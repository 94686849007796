import { Box, Grid, Icon } from "@storyofams/react-ui";
import { usePostHog } from "posthog-js/react";
import { Heading, Button } from "../../../components";
import { getLinkProps } from "../../../lib";
import { Instagram, Facebook, Pinterest, YouTube } from "../Icons";

type LinkListProps = {
  title?: string;
  list: any;
  _uid?: string;
};

export const LinkList = ({ title, list, _uid }: LinkListProps) => {
  const posthog = usePostHog();
  const icons = [
    { label: "instagram", icon: Instagram },
    {
      label: "facebook",
      icon: Facebook,
    },
    { label: "pinterest", icon: Pinterest },
    { label: "youtube", icon: YouTube },
  ];
  return (
    <Box width={["100%", "20%"]}>
      <Heading
        variant="h6"
        as="h6"
        mb={[1.5, 2]}
        css={`
          text-transform: uppercase;
        `}
      >
        {title || "Updates?"}
      </Heading>

      <Grid rowSize={[2, 1]} rowGap={[1, 0.5]}>
        {list?.map(({ label, url, icon, _uid }) => {
          const svg = icon && icons.find((i) => icon === i.label)?.icon;
          // TODO: 3300 remove ternary after adding new tool instead of helpscout
          return getLinkProps(url).includes("beacon") ? (
            <Button
              key={_uid}
              onClick={() => {}}
              variant="link"
              display="flex"
              alignItems="center"
              textAlign="left"
              justifyContent="flex-start"
              color="grey200"
              fontSize={1.75}
              fontWeight="medium"
              mb={icon && 1}
            >
              {icon && <Icon icon={svg} fontSize={3} mr={1} />}
              {label}
            </Button>
          ) : (
            <Button
              key={_uid}
              href={getLinkProps(url)}
              target={svg && "_blank"}
              variant="link"
              display="flex"
              alignItems="center"
              textAlign="left"
              justifyContent="flex-start"
              color="grey200"
              fontSize={1.75}
              fontWeight="medium"
              // onClick={() => {
              //   if (icon === "instagram")
              //     posthog.capture("Clicked on Instagram Footer");
              //   if (icon === "facebook")
              //     posthog.capture("Clicked on Facebook Footer");
              // }}
              mb={icon && 1}
            >
              {icon && <Icon icon={svg} fontSize={3} mr={1} />}
              {label}
            </Button>
          );
          //  (
          //   <Button
          //     key={_uid}
          //     href={getLinkProps(url)}
          //     target={svg && "_blank"}
          //     variant="link"
          //     display="flex"
          //     alignItems="center"
          //     textAlign="left"
          //     justifyContent="flex-start"
          //     color="grey200"
          //     fontSize={1.75}
          //     fontWeight="medium"
          //     mb={icon && 1}
          //   >
          //     {icon && <Icon icon={svg} fontSize={3} mr={1} />}
          //     {label}
          //   </Button>
          // );
        })}
      </Grid>
    </Box>
  );
};
