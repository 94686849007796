import { Box, Icon } from '@storyofams/react-ui';
import { ApplePay, Mastercard, Visa, Bitcoin, Sofort, Ideal } from '../Icons';

export const PaymentIcon = ({ icon }) => {
  const icons = [
    { label: 'applepay', icon: ApplePay, fontSize: 4 },
    { label: 'mastercard', icon: Mastercard, fontSize: 3 },
    { label: 'visa', icon: Visa, fontSize: 4 },
    { label: 'sofort', icon: Sofort, fontSize: 2.5 },
    { label: 'bitcoin', icon: Bitcoin, fontSize: 2 },
    { label: 'ideal', icon: Ideal, fontSize: 2.5 },
  ];

  const paymentIcon = icon && icons.find((i) => icon === i.label);

  return (
    <Box
      width={[46, 48]}
      height={26}
      p={0.75}
      bg="grey600"
      borderRadius="2px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {paymentIcon && (
        <Icon icon={paymentIcon.icon} fontSize={paymentIcon.fontSize} />
      )}
    </Box>
  );
};
