import { Box } from "@storyofams/react-ui";
import { DiscountPopup } from "./DiscountPopup";
import { FeedbackPopup } from "./FeedbackPopup";
import { SocialMediaPopup } from "./SocialMediaPopup";

interface Props {
  days?: number;
  seconds?: number;
  popup?: any;
}

export const Popups: React.FC<Props> = ({ days, seconds, popup }) => {
  return (
    <Box>
      {popup.map((content) => {
        switch (content.component) {
          case "discount_coupon_popup":
            return <DiscountPopup key={content.component} content={content} />;
          case "social_media_pop_up":
            return (
              <SocialMediaPopup key={content.component} content={content} />
            );
          case "feedback_survey_popup":
            return <FeedbackPopup key={content.component} content={content} />;
          default:
            return null;
        }
      })}
    </Box>
  );
};
