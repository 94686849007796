import { useShopify } from "~context";

import { CheckoutLineItem } from "./CheckoutLineItem";

export const LineItems = ({
  isInModal,
  tiered_enabler,
}: {
  isInModal?: boolean;
  tiered_enabler?: boolean;
}) => {
  const { cart } = useShopify();

  return (
    <>
      {cart?.lines?.edges?.map(({ node }, i) => (
        <CheckoutLineItem
          discountAllocations={cart.discountAllocations}
          tiered_enabler={tiered_enabler}
          key={node.id}
          last={cart?.lines?.edges?.length === i + 1}
          item={node}
          isInModal={isInModal}
        />
      ))}
    </>
  );
};
