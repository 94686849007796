import { Stack, Flex, Grid, Icon, Box, css } from "@storyofams/react-ui";
import { format } from "date-fns";

import { usePostHog } from "posthog-js/react";
import {
  Image,
  Text,
  Divider,
  Button,
  PaymentOptions,
} from "../../../components";
import { getLinkProps } from "../../../lib";
import { ArrowLeft, Logo } from "../Icons";
import { LinkList } from "./LinkList";

export const Footer = ({ content }) => {
  const posthog = usePostHog();
  const {
    description,
    review_icon,
    review_text,
    link_list,
    social_title,
    social_links,
    payment_options,
    general_links,
  } = content || {};

  return (
    <Box bg="grey800" color="white" as="footer">
      <Box
        px={2}
        maxWidth={"1332px"}
        width="100%"
        py={[5, 10]}
        display="flex"
        flexDirection="column"
        mx="auto"
      >
        <Flex
          alignItems={["center", "center", "end"]}
          justifyContent={["center", "center", "space-between"]}
          flexDirection={["column", "column", "row"]}
        >
          <Flex
            flexDirection="column"
            alignItems={["center", "center", "start"]}
            mb={[5, 5, 0]}
          >
            <Button href="/">
              <Icon
                icon={Logo}
                width={132}
                height={25}
                css={css({ svg: { width: "100%", height: "100%" } })}
              />
            </Button>
            <Text
              fontStyle="italic"
              mt={1.5}
              textAlign={["center", "center", "left"]}
            >
              {description}
            </Text>
          </Flex>
          <Button
            display="flex"
            alignItems="center"
            // onClick={() => posthog.capture("Clicked on Kiyoh Footer")}
            href="https://www.kiyoh.com/reviews/1041941/natural_heroes_1"
          >
            <Image
              src={review_icon?.filename}
              alt={review_icon?.alt}
              layout="fill"
              height={24}
              width={24}
              objectFit="contain"
              mr={1}
            />
            <Text fontSize={1.75}>{review_text}</Text>
          </Button>
        </Flex>

        <Divider
          display={["block", "block", "none !important"]}
          my={5}
          color="grey600"
        />
        <Grid
          rowSize={[1, 3, 5]}
          rowGap={[5, 5, 4]}
          mt={[0, 0, 10]}
          mb={[0, 0, 2]}
          flexDirection={["column", "row", "row"]}
          width="100%"
          fontSize={2}
          lineHeight="high"
        >
          {link_list?.map((link) => (
            <LinkList key={link?._uid} {...link} />
          ))}

          <LinkList list={social_links} title={social_title} />
        </Grid>

        <Divider my={[5, 5, 6]} color="grey600" />

        <Flex position="relative" justifyContent="space-between">
          <PaymentOptions options={payment_options} />

          <Button
            ariaLabel="Scroll up"
            position="absolute"
            right={[0, 2, 0]}
            variant="unstyled"
            display={["none", "block", "block"]}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Icon fontSize={3} icon={ArrowLeft} transform="rotate(90deg)" />
          </Button>
        </Flex>

        <Flex position="relative">
          <Stack
            space={[2, 2, 3]}
            mt={[6, 6, 4]}
            alignItems="center"
            justifyContent="center"
            flexDirection={["column", "row", "row"]}
            flex={1}
          >
            {general_links?.map((link) => (
              <Button
                key={link?._uid}
                fontSize={1.5}
                color="grey200"
                href={getLinkProps(link.url)}
                borderRight={[0, "1px", "1px"]}
                borderColor="grey200"
                borderRadius={0}
                pr={[0, 3, 3]}
              >
                {link?.label}
              </Button>
            ))}
            <Button fontSize={1.5} color="grey200" href={"/"}>
              © Natural Heroes {format(new Date(), "yyyy")}
            </Button>
          </Stack>

          <Button
            position="absolute"
            bottom={0}
            right={0}
            variant="unstyled"
            display={["block", "none", "none"]}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Icon fontSize={3} icon={ArrowLeft} transform="rotate(90deg)" />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
