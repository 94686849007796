import { FC } from "react";
import { Icon, css, SystemProps, Flex } from "@storyofams/react-ui";

import { Button } from "../../../../components/common/Button";
import { ArrowUp, NavUnderline } from "../../Icons";

interface NavigationLinkProps extends SystemProps {
  link?: string;
  active?: boolean;
  hover?: boolean;
  onClick?(): void;
  bold?: boolean;
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  link,
  bold,
  active,
  hover,
  children,
  ...props
}) => {
  return (
    <Flex alignItems={"center"}>
      <Icon
        pt="1px"
        fontSize={"10px"}
        transform="rotate(90deg)"
        icon={ArrowUp}
        mr="7px"
        opacity={0.3}
      />
      <Button
        href={link}
        target="_self"
        variant="link"
        textAlign={"start"}
        borderRadius={"none"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        display="flex"
        fontFamily="heading"
        width={["100%", "100%", "auto"]}
        fontSize={1.75}
        lineHeight={["normal", "normal", "high"]}
        color="grey800"
        position="relative"
        justifyContent={["space-between", "space-between", "center"]}
        css={css({
          ".underline": {
            position: "absolute",
            right: "-4px",
            height: 12,
            bottom: 0,
            width: active ? "calc(100% + 8px)" : 0,
            transition: "width 0.32s ease-in-out",
            opacity: [0, 0, active ? 1 : 0],
            zIndex: -1,

            svg: {
              width: "100%",
              height: "100%",
              color: "sand",
            },
          },

          "&&:hover": hover
            ? {
                opacity: 1,
                ".underline": {
                  width: "calc(100% + 8px)",
                  opacity: [0, 0, 1],
                },
              }
            : { opacity: 1 },
        })}
        {...props}
      >
        {children}
        <Icon className="underline" icon={NavUnderline} width="100%" />
      </Button>
    </Flex>
  );
};
