import { useEffect, useState } from "react";
import { Box, Flex, Icon, Text } from "@storyofams/react-ui";
import Image from "next/image";
import { css } from "styled-components";
import { useShopify } from "~context";
import { showToast } from "~lib";
import { shopifySdk } from "~lib/shopify/client";
import { Cart, Plus } from "../Icons";
import { Modal } from "../Modal";
import { RichText } from "../RichText";
export const DiscountPopup = ({ content }) => {
  const { cart, createEmptyCart } = useShopify();
  const [isOpen, setIsOpen] = useState(false);
  const setDiscountCode = async () => {
    try {
      if (!cart?.id) await createEmptyCart(content.discount);
      else
        await shopifySdk.cartDiscountCodesUpdate({
          cartId: cart.id,
          discountCodes: [content.discount],
        });
      showToast("De code wordt toegepast bij het afrekenen!", "success");
      setIsOpen(false);
    } catch {
      showToast("Code not applied", "error");
    }
  };
  const popupHandle = () => {
    setIsOpen(true);
    document.cookie = "discount=1";
  };
  useEffect(() => {
    const decodedCookies = decodeURIComponent(document.cookie).split(";");
    const { discount: isReturning } = Object.assign(
      {},
      ...decodedCookies.map((cookie) => {
        const [key, value] = cookie.split("=");
        return { [key.trim()]: value };
      })
    );
    let timerId;
    if (!isReturning) {
      timerId = setTimeout(popupHandle, Number(content.delay + "000"));
    }
    return () => {
      clearInterval(timerId);
    };
  }, []);
  if (!content?.isEnabled) return null;
  return (
    <Modal
      overflow="visible"
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
      }}
    >
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
      >
        {content?.hero?.filename && (
          <Box
            mx="auto"
            top={-8}
            left={0}
            right={0}
            width={130}
            height={130}
            position={"absolute"}
          >
            <Image layout="fill" src={content?.hero?.filename} />
          </Box>
        )}
        <Box mt="40px">
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Text
              textAlign={"center"}
              fontFamily={"DINPro"}
              fontSize={"28px"}
              fontWeight={700}
              px="10px"
            >
              {content?.title.toUpperCase()}
            </Text>
            <RichText text={content?.body} mt="20px" mb="30px" />
            <Text mb="10px" fontWeight={500} fontSize={"18px"}>
              {content?.cta}
            </Text>
            <Box
              width={"100%"}
              height={"54px"}
              borderRadius={"8px"}
              border={"1px dashed black"}
              position="relative"
              pt="12px"
              onClick={() => {
                setDiscountCode();
              }}
              cursor={"pointer"}
            >
              <Text fontFamily={"DINPro"} fontWeight={700} fontSize={"20px"}>
                {content?.discount}
              </Text>
              <Flex position={"absolute"} top={0} bottom={0} right={2}>
                <Icon mr={1} fontSize={"20px"} icon={Plus}></Icon>
                <Icon fontSize={"20px"} icon={Cart}></Icon>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};
