import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import Script from "next/script";
import { useShopify } from "~context";
import { Layout } from "../components";
import { getDisplayName } from "./getDisplayName";

export const collections = [
  "Draagoliën",
  "Geuroliën",
  "Hydrosolen",
  "(Basis) Zepen",
  "Klei & Poeders",
  "Kruiden & Plantpoeders",
  "Boters & Wassen",
  "Hulpstoffen",
];

export function withLayout(Component: any) {
  const LayoutComponent = ({ footer, navigation, cart, ...props }) => {
    const productBg =
      props?.story?.content?.bgColor ||
      props?.story?.content?.backgroundColor ||
      "";
    const footerColor = props?.story?.content?.footer_color?.color
    let collection = null;
    props?.product?.collections?.edges?.map(({ node }) => {
      collections?.forEach((item) => {
        if (item.toLowerCase().includes(node?.title?.toLowerCase())) {
          collection = item;
        }
      });
    });
    if (props?.collection?.title) {
      collection = props?.collection?.title;
    }
    if (props?.story?.content?.gradient_enabled)
      collection = props.story.content.background_color;
    if (props?.story?.content?.bg_enabled)
      collection = `background : ${props?.story?.content?.bg_color?.color}`;
    return (
      <Layout
        footer={footer}
        navigation={navigation}
        cart={cart}
        footerColor={footerColor}
        gradientType={collection}
        productBg={productBg}
      >
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
