import { FC, useState, useRef, useEffect } from "react";
import { Flex, Box, SystemProps, Text, Stack } from "@storyofams/react-ui";

import { Image } from "@storyofams/storyblok-toolkit";
import { getLinkProps, useClickOutside } from "~lib";

import { Button } from "~components/common/Button";
import { Stars } from "~components/product/Reviews/Stars";
import { NavigationLink } from "./NavigationLink";
import { NavigationLinkNew } from "./NavigationLinkNew";
import { NavigationMegaLinkMobile } from "./NavigationMegaLinkMobile";
import { NavigationMegaLinkMobile1col } from "./NavigationMegaLinkMobile1col";
import { NavigationMegaSubLinks } from "./NavigationMegaSubLinks";

export interface NavigationMegaLinkProps extends SystemProps {
  megaData: any;
  mobileMenuOpen: boolean;
  setMobileMenuOpen?(boolean): void;
}

export const NavigationMegaLink: FC<NavigationMegaLinkProps> = ({
  megaData,
  mobileMenuOpen,
  setMobileMenuOpen,
}) => {
  return (
    <Box display="flex" width={"100%"} flexDirection="column">
      <NavigationMegaLinkMobile
        mobileMenuOpen={mobileMenuOpen}
        megaData={megaData}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <Stack mt="37px" flexDirection={"column"} space={"10px"} px="18px">
        <Flex justifyContent={"space-between"}>
          <Stars stars={5}></Stars>
          <Text fontSize={"15px"} color={"#767473"}>
            {megaData.review_date}
          </Text>
        </Flex>
        <Text fontSize={"15px"} fontFamily={"DINPro"}>
          {megaData.review_text}
        </Text>
        <Flex alignItems={"center"}>
          <Box>
            <Image
              width={24}
              height={24}
              src="https://a.storyblok.com/f/125270/24x24/ddf4704ac1/profiel.png"
            />
          </Box>
          <Text
            fontSize={"14px"}
            fontWeight={500}
            ml="6px"
            fontFamily={"DINPro"}
          >
            {megaData.review_name}
          </Text>
        </Flex>
      </Stack>
    </Box>
  );
};
